import React, { useState } from 'react';
import { createTrackingEvent } from "lib/tracking";

export default function LinkTrackCopy({ name, url, trackingName }) {
  const [interacted, setInteracted] = useState(false)

  const handleInteraction = () => {
    if (!interacted) {
      createTrackingEvent(`Storefront - ${trackingName}`, {});
      setInteracted(true);
    }
  };

  return (
    <a
      href={url}
      onCopy={handleInteraction}
      onClick={handleInteraction}
      className="subtle"
      target="_blank">
      {name}
    </a>
  );
}